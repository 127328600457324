<template>
  <div>
    <b-row>
      <b-col><p v-if="applyAll"><v-fa :icon="['fas', 'spinner']" pulse size="2x" /></p></b-col>
      <b-col>
        <b-button style="background-color: #5F769A" class="float-right mt-2" v-on:click="setDefaultSettings">デフォルト設定を全チャンネルに適応</b-button>
      </b-col>
    </b-row>
    <b-table striped hover responsive :items="channels" :fields="fields" class="mt-2">
      <template #cell(publishDate)="data">
        <div v-if="data.item.settings">
          <div v-for="(value, name) in data.item.settings.publish_weekdays" v-bind:key="name">
            <label v-bind:for="name">{{ name }}</label>
            <input type="checkbox" v-bind:id="name" v-model="data.item.settings.publish_weekdays[name]">
          </div>
        </div>
      </template>
      <template #cell(formSettings)="data">
        <div v-if="data.item.settings">
          <b-form-group id="time-group" label-for="time" label="配信時刻">
            <b-form-input id="time" v-model="data.item.settings.publish_time"/>
          </b-form-group>
          <b-form-group id="min-price-group" label-for="min-price" label="最低価格">
            <input id="min-price" v-model="data.item.settings.min_price">
          </b-form-group>
          <b-form-group id="max-price-group" label-for="max-price" label="最高価格">
            <input id="max-price" v-model="data.item.settings.max_price">
          </b-form-group>
        </div>
        <p v-if="data.item._id === processing"><v-fa :icon="['fas', 'spinner']" pulse size="2x" /></p>
        <b-button size="sm" class="mr-2" style="background-color: #5F769A" v-on:click="save(data.item._id)">保存</b-button>
      </template>
      <template #cell(lunchBoxes)="data">
        <b-button :to="{name: 'LunchBoxes', params: {channelId: data.item.channel.id}}">弁当</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
  import Axios from "axios"
  export default {
    data() {
      return {
        channels: [],
        fields: [
          "channel.id",
          "channel.name",
          "isActive",
          "members",
          "address[0].postalcode",
          "authed_user.id",
          "bot_user.id",
          "lunchBoxes",
          "publishDate",
          "formSettings",
          "team.enterprise"
        ],
        header: {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}},
        processing: '',
        applyAll: false
      }
    },
    async mounted(){
      this.channels = await this.getChannels()
    },
    methods: {
      async getChannels(){
        try {
          const res = await Axios.get('/v1/slack-channels/index', this.header)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      async save(id){
        const channel = this.channels.find(channel => channel._id === id)
        try {
          this.processing = id
          await Axios.post('/v1/slack-channels/edit', channel, this.header)
          this.processing = ''
        }catch (e){
          alert(e)
        }
      },
      async setDefaultSettings(){
        this.applyAll = true
        await Promise.all(this.channels.map(async channel => {
          if (!channel.settings){
            channel.settings = {
              publish_time: '11:00',
              publish_weekdays: {
                Mon: true,
                Tue: true,
                Wed: true,
                Thu: true,
                Fri: true,
                Sat: true,
                Sun: true
              },
              min_price: 500,
              max_price: 2000
            }
            await this.save(channel._id)
          }
        }))
        this.applyAll = false
      }
    }
  }
</script>