import Vue from "vue";
import Router from "vue-router";
// import Index from "./views/Index.vue";
import Deliveries from "./views/Deliveries.vue";
import Areas from "./views/Areas.vue";
import EditAreas from "./views/EditAreas.vue";
import Channels from "./views/Channels.vue";
import LunchBoxes from "./views/LunchBoxes.vue";
import EditLunchBox from "./views/EditLunchBox.vue";
import SearchWords from "./views/SearchWords.vue";
import Messages from "./views/Messages.vue";
import Orders from "./views/Orders.vue";
import Addresses from "./views/Addresses.vue";
import Menus from "./views/Menus.vue";
import EditMenu from "./views/EditMenu.vue";
import Stores from "./views/Stores.vue";
import EditStore from "./views/EditStore.vue";
import Login from "./views/Login.vue";
import ScrapyLogs from "./views/ScrapyLogs.vue";
import store from "./store/index.js"

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "login"
    },
    {
      path: "/deliveries",
      name: "Deliveries",
      component: Deliveries
    },
    {
      path: "/areas",
      name: "Areas",
      component: Areas
    },
    {
      path: "/areas/edit/:id",
      name: "EditAreas",
      component: EditAreas
    },
    {
      path: "/channels",
      name: "Channels",
      component: Channels
    },
    {
      path: "/lunch-boxes",
      name: "LunchBoxes",
      component: LunchBoxes
    },
    {
      path: "/lunch-boxes/edit/:id",
      name: "EditLunchBox",
      component: EditLunchBox
    },
    {
      path: "/search-words",
      name: "SearchWords",
      component: SearchWords
    },
    {
      path: "/messages",
      name: "Messages",
      component: Messages
    },
    {
      path: "/orders",
      name: "Orders",
      component: Orders
    },
    {
      path: "/addresses",
      name: "Addresses",
      component: Addresses
    },
    {
      path: "/menus",
      name: "Menus",
      component: Menus
    },
    {
      path: "/menus/edit/:id",
      name: "EditMenu",
      component: EditMenu
    },
    {
      path: "/stores",
      name: "Stores",
      component: Stores
    },
    {
      path: "/stores/edit/:id",
      name: "EditStore",
      component: EditStore
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/scrapy-logs",
      name: "ScrapyLogs",
      component: ScrapyLogs
    },
  ]
});

router.beforeResolve((to, from, next) => {
  if (store.state.loggedIn == true) {
    next();
  } else {
    if (to.path == '/login') {
      next()
    } else {
      next({ path: '/login', redirect: 'login' })
    }
  }

})

export default router