<template>
  <div>
    <b-row>
      <b-col><b-button class="float-right mt-3 mr-2" :to="{name: 'EditStore', params: { id: 'new'}}">+ 店舗を作成</b-button></b-col>
    </b-row>
    <b-row>
      <b-col v-for="item in items" :key="item._id" cols=12 md=3 class="mt-3">
        <b-card>
          <b-card-body>
            <b-card-title>{{item.name}}</b-card-title>
            <b-card-sub-title>
            </b-card-sub-title>
          </b-card-body>
          <b-button :to="{name: 'EditStore', params: { id: item._id}}">編集</b-button>
          <!-- <b-button :to="{ name: 'EditStores', params: { id: item._id}}" variant="primary">編集</b-button> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Axios from 'axios'
  export default {
    data() {
      return {
        items: [],
        showNotActiveStores: false
      }
    },
    async mounted(){
      this.items = await this.getStores()
    },
    methods: {
      async getStores(){
        const header = {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
        try {
          const res = await Axios.get('/v1/stores/index', header)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      }
    }
  }
</script>