<template>
    <div>
        <div v-html="rawHtml"></div>
    </div>
</template>
<script>
  import Axios from 'axios'
  export default {
    data() {
      return {
        rawHtml: "",
      }
    },
    async mounted(){
      this.rawHtml = await this.getScrapy()
    },
    methods: {
      async getScrapy(){
        try {
          const res = await Axios.get('/jobs')
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
    }
  }
</script>