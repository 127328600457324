<template>
  <div>
     <div><b-img :src="data.image" fluid alt="Fluid image"></b-img></div>
     <div>
         <label class="browse">+ファイルを追加
             <input type="file" ref="file" @change="setImage" style="display: none"/>
         </label>
     </div>
  </div>
</template>
<script>
import imageCompression from 'browser-image-compression';
export default {
  data() {
    return {
      data: {
        image: "",
        name: "",
        obj: ""
      }
    };
  },
  methods: {
    async setImage() {
      const files = this.$refs.file;
      const fileImg = await this.getCompressImageFileAsync(files.files[0]);
      if (fileImg.type.startsWith("image/")) {
        this.data = {
          image: await this.getDataUrlFromFile(fileImg),
          name: fileImg.name,
          type: fileImg.type,
          obj: fileImg,
        }
        this.$emit('uploaded', this.data)
      }
    },
    // アップロードされた画像ファイルを取得
    async getCompressImageFileAsync(file) {
      const options = {
        maxSizeMB: 1, // 最大ファイルサイズ
        maxWidthOrHeight: 600 // 最大画像幅もしくは高さ
      };
      try {
        // 圧縮画像の生成
        return await imageCompression(file, options);
      } catch (e) {
        alert(`Error: ${e}`);
      }
    },
    // プレビュー表示用のdataurlを取得
    async getDataUrlFromFile(file) {
      try {
        return await imageCompression.getDataUrlFromFile(file);
      } catch (e) {
        alert(`Error: ${e}`);
      }
    }
  }
};
</script>
<style>
.browse {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px;
}
</style>