<template>
    <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand style="color: #324158">{{ pageName }}</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/deliveries" >🛵 配達</b-nav-item>
            <b-nav-item to="/stores">🍱 店舗</b-nav-item>
            <b-nav-item to="/lunch-boxes">🍱 お弁当</b-nav-item>
            <b-nav-item to="/search-words">🔍 検索ワード</b-nav-item>
            <b-nav-item to="/menus">📅 メニュー</b-nav-item>
            <b-nav-item to="/orders">💰 注文</b-nav-item>
            <b-nav-item to="/channels">🏢 チャンネル</b-nav-item>
            <b-nav-item to="/addresses">🏢 住所</b-nav-item>
            <b-nav-item to="/areas">🏯 対応エリア</b-nav-item>
            <b-nav-item to="/scrapy-logs">🕷 Scrapy Log</b-nav-item>
            <b-nav-item  to="/messages">✉️ メッセージ送信</b-nav-item>
            <!-- <b-nav-item >⚙️ 設定</b-nav-item> -->

            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template v-slot:button-content>
                  <em style="color: #324158">{{ userName }}</em>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item v-on:click="logout()">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>
<script>
export default {
    computed: {
        userName(){
            return this.$store.state.user.user_name
        },
        pageName(){
          const pageNames = {
            "Deliveries":"配達",
            "Stores":"店舗",
            "LunchBoxes":"お弁当",
            "SearchWords": "検索ワード",
            "EditLunchBox": "編集:お弁当",
            "Menus":"メニュー",
            "Orders":"注文",
            "Channels":"チャンネル",
            "Addresses":"住所",
            "Areas": "対応エリア",
            "EditAreas": "編集:エリア",
            "Messages": "メッセージ送信"
          }
          return pageNames[this.$route.name]
        }
    },
    methods: {
      logout() {
        try {
          this.$store.dispatch("logout")
          this.$router.push('/login')
        } catch(error) {
          alert(`login failed: ${error}`)
        }
      }
    }
}
</script>