<template>
  <div>
    <h1>{{title}}</h1>
    <div v-if="channelNotSet">
      <b-row><b-col><b-button class="float-right mt-3 mr-2" :to="{name: 'EditLunchBox', params: { id: 'new'}}">+お弁当を作成</b-button></b-col></b-row>
      <b-row align-v="center">
        <b-col>
          <label for="postal-code">郵便番号(ハイフン無し)<small class="text-danger">必須</small></label>
          <b-form-input id="postal-code" v-model="form.postal_code"></b-form-input>
        </b-col>
        <b-col>
          <label for="area-code">楽天エリアコード</label>
          <b-form-input id="area-code" v-model="form.rakuten_area_code"></b-form-input>
        </b-col>
        <b-col>
          <b-button style="background-color: #5F769A" block v-on:click="registerByRakuten">+楽天デリバリーからお弁当を作成</b-button>
        </b-col>
      </b-row>
    </div>
    <div class="mt-3">
      <label for="keyword">キーワード</label>
      <b-form-input id="keyword" v-model="keyword"></b-form-input>
    </div>
    <div class="mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="lunchbox-cards"
      ></b-pagination>
    </div>
    <b-row id="lunchbox-cards">
      <b-col v-for="lunchBox in currentItems" v-bind:key="lunchBox._id" cols=12 md=4 class="mt-3">
        <b-card style="height:100%" :bg-variant="cardColor(lunchBox.is_active)">
          <b-card-body>
            <b-card-title>{{ lunchBox.name }}</b-card-title>
            <b-card-img v-bind:src="lunchBox.image" class="rounded-0" v-bind:class="imgStyle(lunchBox.is_active)"/>
            <b-card-text>
              価格(税込): {{ lunchBox.price }}円<br>
              <b-button v-on:click="inactivate(lunchBox)" :variant="buttonColor(lunchBox.is_active)">{{ lunchBox.is_active ? "無効化" : "有効化" }}</b-button><br>
              必ず選択される: {{ lunchBox.will_chosen }}<br>
              楽天デリバリーURL: <a v-bind:href="`https://delivery.rakuten.co.jp${lunchBox.rakuten_url}`" target="_black">別タブで開く</a>
            </b-card-text>
            <div>作成日: {{ dateFormat(lunchBox.created_at) }}</div>
            <div>更新日: {{ dateFormat(lunchBox.updated_at) }}</div>
            <b-button :to="{name: 'EditLunchBox', params: { id: lunchBox._id}}">編集</b-button>
            <b-button v-b-modal="modalId(lunchBox._id)" variant="danger">削除</b-button>
            <b-modal :id="'remove'+lunchBox._id" title="弁当の削除" @ok="deleteLunchBox(lunchBox._id)">
              <p class="my-4">弁当を削除しますか？</p>
            </b-modal>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div class="mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="lunchbox-cards"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import Axios from "axios"
import moment from 'moment'
moment.locale('ja');
export default {
  data() {
    return {
      title: '全チャンネルのお弁当',
      channelNotSet: true,
      items: [],
      header: {Authorization: `JWT ${this.$store.state.authentication.token}`},
      form: {
        postal_code: ''
      },
      currentPage: 1,
      perPage: 50,
      keyword: '',
    }
  },
  computed: {
    rows() {
      return this.filteredItems.length
    },
    currentItems() {
      return this.filteredItems.slice(
        (this.currentPage -1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    filteredItems(){
      return this.items.filter(item => item.name.includes(this.keyword) )
    }
  },
  async mounted() {
    if (this.$route.params.channelId){
      this.title = `選択中のチャンネル: ${this.$route.params.channelId}`
      this.channelNotSet = false
      this.items = await this.getByChannelId(this.$route.params.channelId)
    } else {
      this.items = await this.getLunchBoxes()
    }
  },
  methods: {
    async getByChannelId(channelId){
      try {
        const res = await Axios.get('/v1/slack-channels/lunch-boxes', {params: {channelId: channelId}, headers: this.header})
        return res.data
      } catch (e) {
        alert(e)
      }
    },
    async getLunchBoxes(){
      try {
        const res = await Axios.get("/v1/lunch-boxes/index", {headers: this.header})
        return res.data
      } catch (e) {
        alert(`Error: ${e}`)
      }
    },
    async inactivate(lunchBox) {
      try {
        lunchBox.is_active = !lunchBox.is_active
        const modified = await Axios.post("/v1/lunch-boxes/edit", lunchBox,{headers: this.header})
        this.items = this.items.map(item => item._id === modified._id ? modified : item)
      } catch (e) {
        alert(e)
      }
    },
    cardColor(isActive){
      return isActive ? "light" : "secondary"
    },
    buttonColor(isActive){
      return isActive ? "secondary" : "primary"
    },
    imgStyle(isActive){
      return isActive ? { isActive: true } : { isNotActive: true }
    },
    /**
     * 指定した弁当を一覧から削除して更新する
     * @param {String} _id - 弁当のID
     * @return - Void
     */
    async deleteLunchBox(_id){
      await this.remove(_id)
      alert(`${_id}を削除しました`)
      this.items = this.items.filter(item => item._id !== _id)
    },
    /**
     * マスターから弁当を削除する
     * @param {String} _id - 削除する弁当のID
     */
    async remove(_id){
      try {
        const res = await Axios.post("/v1/lunch-boxes/delete", {"_id": _id},{headers: this.header})
        return res.data
      } catch (e) {
        alert(e.toString())
      }
    },
    modalId(key){
      return `remove${key}`
    },
    dateFormat(date){
      return moment(date).format('YYYY-MM-DD')
    },
    /**
     * 楽天デリバリーから弁当を登録する
     */
    async registerByRakuten(){
      await this.register(this.form.postal_code)
      alert(`${this.form.postal_code}に該当する楽天デリバリーの店舗、弁当登録をジョブに追加しました`)
    },
    /**
     * 楽天デリバリーから弁当を登録する
     * @param {String} postalCode - 郵便番号(ハイフン無し)
     */
    async register(postalCode){
      try {
        const res = await Axios.post("/v1/lunch-boxes/register", {'postal_code': postalCode},{headers: this.header})
        return res.data
      } catch (e) {
        alert(`Error: ${e}`)
      }
    }
  }
}
</script>
<style scoped>
  .isActive {
    filter:grayscale(0)
  }
  .isNotActive {
    filter:grayscale(1)
  }
</style>