<template>
  <div>
    <b-card>
      <div><b-img v-bind:src="lunchBox.image" fluid/></div>
      <p class="text-muted">{{ lunchBox._id }}</p>
      <div>{{ lunchBox.name }}</div>
      <div>{{ lunchBox.price }}円</div>
      <div>{{ lunchBox.calory }}KCal</div>
      <div>{{ lunchBox.explain }}</div>
      <b-row>
        <b-col cols="6">
          <div>在庫数: {{ stockNow }}</div>
            <div v-if="isButtonDisabled">
              <v-fa :icon="['fas', 'spinner']" pulse size="2x" />
            </div>
        </b-col>
        <b-col cols="6">
          <b-button v-on:click="updateStocks" class="float-right">在庫更新</b-button>
        </b-col>
      </b-row>
      <b-form-group label="最低注文数" label-for="lunchBox.min" class="mt-3">
        <div v-if="lunchBox._id">
          <validation-provider rules="numeric" v-slot="{ errors }">
            <b-form-input id="min" v-model="min" v-bind:readonly="isReadOnly" @input="emit"></b-form-input>
            <b-button v-on:click="addMinPurchase(lunchBox.min)">更新</b-button>
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </div>
      </b-form-group>
      <b-form-group label="入庫数" label-for="stock">
        <validation-provider rules="numeric" v-slot="{ errors }">
          <b-form-input id="sctock" v-model="stock"></b-form-input>
          <b-button v-on:click="addStocksAndReflect(stock)">入庫</b-button>
          <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider>
      </b-form-group>
      <b-button v-on:click="deleteStockAndReflect">-1</b-button>
    </b-card>
  </div>
</template>
<script>
import Axios from 'axios';
import moment from 'moment';
import { ValidationProvider, extend } from 'vee-validate';
import { numeric } from 'vee-validate/dist/rules';
  extend('numeric', {
    ...numeric,
    message: '半角数字で入力してください'
  });
export default {
  props: {
    'lunchBox': Object,
    'menuId': String,
    'isMinimumOrderChangable': Boolean,
    'deliveryDate': Date
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      min: this.lunchBox.min,
      stockNow: 0,
      isButtonDisabled: false,
      stock: 0,
      isReadOnly: !this.isMinimumOrderChangable,
      header: {Authorization: `JWT ${this.$store.state.authentication.token}`}
    }
  },
  async mounted() {
    await this.updateStocks()
  },
  methods: {
    emit() {
      this.$emit("input", this.min);
    },
    /**
     * メニューの弁当に在庫を追加し、最新の在庫状況を反映する
     * @return {Void}
     */
    async addStocksAndReflect(stock){
      try {
        await this.addStocks(stock, this.lunchBox)
        this.updateStocks()
      }catch(e){
        alert(`Error: ${e}`)
      }
    },
    /**
     * メニューの弁当に在庫を1減らし、最新の在庫状況を反映する
     * @return {Void}
     */
    async deleteStockAndReflect(){
      try {
        await this.deleteStock()
        this.updateStocks()
      }catch(e){
        alert(`Error: ${e}`)
      }
    },
    /**
     * メニューの弁当に最低注文数を追加する
     * @param {Integer} lunchBox.min - 最低注文数
     * @return {Void}
     */
    async addMinPurchase(min){
      try {
        const payload = {
          '_id': this.menuId,
          'lunchBoxId': this.lunchBox._id,
          'name': this.lunchBox.name,
          'min': Number(min),
          'lunchBoxUpdate': true
        }
        const res = await Axios.post("/v1/menus/edit", payload, {headers: this.header})
        if(res.status === 201) alert(`作成しました ${res.status}`)
        else if(res.status === 204) alert(`更新しました ${res.status}`)
      } catch (e){
        alert(`Error: ${e}`)
      }
    },
    /**
     * メニューの弁当に在庫を追加する
     * @param {Int} stock - 在庫数
     * @param {Object} lunchBox - 在庫を追加する弁当
     * @return {Array} stockLunches - 追加された弁当
     */
    async addStocks(stock, lunchBox){
      const stockLunch = {
        lunchBoxId: lunchBox._id,
        name: lunchBox.name,
        inStock: true,
        deliveryDate: this.deliveryDate
      } 
      try {
        const payload = {'count': stock, 'stockLunch': stockLunch}
        const res = await Axios.post("/v1/stock-lunches", payload, {headers: this.header})
        return res.data
      } catch (e){
        alert(`Error: ${e}`)
      }
    },
    /**
     * 弁当の在庫を取得する
     * @param {String} lunchBoxId - 弁当マスターのID
     * @return {Int} - 在庫数
     */
    async getStocks(lunchBoxId){
      const filter = {'lunchBoxId' : lunchBoxId, 'inStock': true, 'deliveryDate': moment(this.deliveryDate).format("YYYYMMDD")}
      try {
        const res = await Axios.get("/v1/stock-lunches/count", {params: filter, headers: this.header})
        return res.data.count
      } catch (e) {
        alert(`Error: ${e}`)
      }
    },
    /**
     * 現在の在庫を反映する
     * @return {Void}
     */
    async updateStocks(){
      try {
        this.isButtonDisabled = true
        this.stockNow = await this.getStocks(this.lunchBox._id)
        this.isButtonDisabled =false
      } catch (e){
        alert(`Error: ${e}`)
      }
    },
    async deleteStock(){
      const filter = {'lunchBoxId': this.lunchBox._id, 'deliveryDate': this.deliveryDate}
      const res = await Axios.post("/v1/stock-lunches/delete", filter, {headers: this.header})
      return res.data
    }
  }
}
</script>