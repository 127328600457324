import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    authentication: [],
    user: [],
  },
  mutations: {
    login(state) {
      state.loggedIn = true
    },
    logout(state) {
      state.loggedIn = false
    },
    setUser(state, payload) {
      state.user = payload.user
    },
    resetUser(state) {
      state.user = []
    },
    setAuthentication(state, payload) {
      state.authentication = payload.authentication
    },
    resetAuthentication(state) {
      state.authentication = []
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('login')
      commit('setUser', payload)
      commit('setAuthentication', payload)
    },
    logout({ commit } ){
      commit('logout')
      commit('resetUser')
      commit('resetAuthentication')
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: 'BentoAdmin',
    paths: [
      'loggedIn',
      'authentication',
      'user',
    ],
    storage: window.sessionStorage
  })]
})
