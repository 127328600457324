<template>
    <div>
        <b-row>
            <b-col cols=12 md=9 class="mt-3">
                <b-card>
                    <b-row>
                        <b-col rows=6>
                            <div>
                                <validation-provider rules="required" v-slot="{ errors }">
                                    <label for="procuct-name">商品名<small class="text-danger">必須</small></label>
                                    <b-form-input id="product-name" v-model="form.name"></b-form-input>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                        </b-col>
                        <b-col rows=6>
                            <div>
                                <validation-provider rules="required" v-slot="{ errors }">
                                    <label for="product-price">価格(税込)<small class="text-danger">必須</small></label>
                                    <b-form-input id="product-price" v-model="form.price"></b-form-input>
                                    <span class="text-danger">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                        </b-col>
                    </b-row>
                        <div>
                            <validation-provider rules="numeric" v-slot="{ errors }">
                                <label for="rakuten-price">価格(楽天デリバリー)</label>
                                <b-form-input id="rakuten-price" v-model="form.rakuten_price"></b-form-input>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div>
                            <validation-provider rules="numeric" v-slot="{ errors }">
                                <label for="product-calory">カロリー(Kcal)</label>
                                <b-form-input id="product-calory" v-model="form.calory"></b-form-input>
                                <span class="text-danger">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div>
                            <label for="product-raw-material">原材料</label>
                            <b-form-input id="product-raw-material" v-model="form.material"></b-form-input>
                        </div>
                        <div>
                            <label for="is-active">有効</label>
                            <b-form-select id="is-active" v-model="form.is_active" :options="isActiveOptions"></b-form-select>
                        </div>
                        <div>
                            <label for="will-chosen">配信に必ず選ばれる</label>
                            <b-form-select id="will-chosen" v-model="form.will_chosen" :options="willChosenOptions"></b-form-select>
                        </div>
                        <div>
                            <label for="product-rakuten-url">楽天デリバリーURL</label>
                            <b-form-input id="product-rakuten-url" v-model="form.rakuten_url"></b-form-input>
                        </div>
                        <b-row>
                            <b-col class="mt-2">
                                <label for="start-time">注文開始時間</label>
                                <vue-timepicker 
                                    :format="startTime.format"
                                    :minute-interval="15"
                                    :default-value="date"
                                    :hour-range="[9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23]"
                                    placeholder="時間"
                                    hour-label="時"
                                    minute-label="分"
                                    v-model="startTime"
                                    value="startTime.objectTime"
                                    id="start-time"
                                />
                            </b-col>
                            <b-col class="mt-2">
                                <label for="deadline">注文締切時間</label>
                                <vue-timepicker 
                                    :format="deadline.format"
                                    :minute-interval="15"
                                    :hour-range="[9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23]"
                                    placeholder="時間"
                                    hour-label="時"
                                    minute-label="分"
                                    v-model="deadline"
                                    value="deadline.objectTime"
                                    id="deadline"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label for='start-effective-date'>有効開始日</label>
                                <v-date-picker v-model="form.start_effective_date"/>
                            </b-col>
                            <b-col>
                                <label for='end-effective-date'>有効終了日</label>
                                <v-date-picker v-model="form.end_effective_date"/>
                            </b-col>
                        </b-row>
                </b-card>
            </b-col>
            <b-col cols=12 md=3 class="mt-3">
                <b-card>
                    <div>
                        <label for="status">ステータス</label>
                        <b-form-select v-model="form.status" :options="options" id="status"></b-form-select>
                    </div>
                    <div v-if="form.image">アップロード済みのイメージ<b-img v-bind:src="form.image" fluid/></div>
                    <div><UploadImage @uploaded="uploadEvt"/></div>
                    <div>
                        <b-row>
                            <b-col cols=6><b-button style="color: #949DAE;background-color: white" block>削除</b-button></b-col>
                            <b-col cols=6><b-button style="background-color: #5F769A" block v-on:click = "register">保存</b-button></b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import UploadImage from './UploadImage.vue'
import Axios from "axios"
import { ValidationProvider, extend } from 'vee-validate';
import { required, numeric, digits } from 'vee-validate/dist/rules';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
  extend('required', {
    ...required,
    message: '必須項目です'
  });
  extend('numeric', {
    ...numeric,
    message: '半角数字で入力してください'
  });
  extend('digits', {
    ...digits,
    message: '桁数が異なります'
  });
export default {
    data() {
        return {
            options: [
                {value: null, text: '状態を選択してください'},
                {value: 'onSale', text: '発売中'},
                {value: 'abolished', text: '廃版'}
            ],
            isActiveOptions: [
                {value: true, text: '有効'},
                {value: false, text: '無効'}
            ],
            willChosenOptions: [
                {value: true, text: '必ず選択される'},
                {value: false, text: 'ランダムで選択される'}
            ],
            form: {
                _id: '',
                name: '',
                price: '',
                rakuten_price: '',
                calory: 0,
                material: '',
                status: null,
                image: '',
                start_effective_date: null,
                end_effective_date: null
            },
            postResult: '',
            header: {Authorization: `JWT ${this.$store.state.authentication.token}`, 'Content-Type': 'multipart/form-data'},
            startTime: {
                HH: '09',
                mm: '00',
                format: 'HH:mm', 
            },
            deadline: {
                HH: '11',
                mm: '00',
                format: 'HH:mm', 
            },
            date: null,
        }
    },
    components: {
        UploadImage,
        ValidationProvider,
        VueTimepicker
    },
    async mounted(){
      if (this.$route.params.id !== 'new'){
        this.set(await this.get(this.$route.params.id))
      } else {
        this.reset()
      }
    },
    methods: {
        async register(){
            try {
                if(!this.form.name || !this.form.price) {
                    alert('必須項目を入力してください')
                } else if (this.form.price <= 50) {
                    alert('価格は51円以上にしてください')
                } else if(!this.form.image){
                    alert('お弁当の画像をアップロードしてください')
                }else {
                    const form = new FormData();
                    form. append ( 'obj', this.form.image.obj );
                    for ( const property in this.form ) {
                        form.append ( property, this.form [ property ] );
                    }
                    form.append('start_order_hour', Number(this.startTime.HH))
                    form.append('start_order_minutes',Number(this.startTime.mm))
                    form.append('end_order_hour',Number(this.deadline.HH))
                    form.append('end_order_minutes', Number(this.deadline.mm))
                    const res = await Axios.post("/v1/lunch-boxes/edit", form , {headers: this.header});
                    if (res.status == 200){
                        this.postResult = "修正しました"
                    }
                    if (res.status == 201){
                        this.postResult = "新規登録しました"
                    }
                    alert(this.postResult)
                }
            }catch(e){
                alert(`Error: ${e}`);
            }
        },
        uploadEvt(image){
            this.form.image = image
        },
        async get(id){
            try{
                const payload = {params: {'_id': id}, 'headers': this.header}
                const res = await Axios.get('/v1/lunch-boxes/view', payload)
                return res.data
            }catch(e){
                alert(`Error: ${e}`)
            }
        },
        set(lunchBox){
            this.form._id = this.$route.params.id
            this.form.name = lunchBox.name
            this.form.rakuten_store_id = lunchBox.rakuten_store_id
            this.form.rakuten_item_id = lunchBox.rakuten_item_id
            this.form.rakuten_price = lunchBox.rakuten_price
            this.form.is_active = lunchBox.is_active
            this.form.price = lunchBox.price
            this.form.calory = lunchBox.calory
            this.form.material = lunchBox.material
            this.form.status = lunchBox.status
            this.form.image = lunchBox.image
            this.form.will_chosen = lunchBox.will_chosen
            this.form.rakuten_url = lunchBox.rakuten_url
            this.form.status = lunchBox.status
            this.startTime.HH = lunchBox.start_order_hour ? ('00' + lunchBox.start_order_hour).slice(-2) : '09'
            this.startTime.mm = lunchBox.start_order_minutes ? ('00' + lunchBox.start_order_minutes).slice(-2) : '00'
            this.deadline.HH = lunchBox.end_order_hour ? ('00' + lunchBox.end_order_hour).slice(-2) : '11'
            this.deadline.mm = lunchBox.end_order_minutes ? ('00' + lunchBox.end_order_minutes).slice(-2) : '00'
            this.form.start_effective_date = 'start_effective_date' in lunchBox ? new Date(lunchBox.start_effective_date) : new Date()
            this.form.end_effective_date = lunchBox.end_effective_date ? new Date(lunchBox.end_effective_date) : this.getDefaultEffectiveEndDate()
        },
        reset(){
            this.form._id = ''
            this.form.name = ''
            this.form.rakuten_store_id = ''
            this.form.rakuten_item_id = ''
            this.form.rakuten_price = 0
            this.form.is_active = true
            this.form.price = 0
            this.form.calory = 0
            this.form.status = null
            this.form.image = ''
            this.form.will_chosen = false
            this.form.rakuten_url = ''
            this.form.status = 'onSale'
        },
        getDefaultEffectiveEndDate(){
            const dt = new Date()
            dt.setFullYear(dt.getFullYear() + 10)
            return dt
        }
    }
}
</script>