<template>
  <div>
    <b-row>
      <b-col>
        <b-form-checkbox
          id="areaActiveCheckbox"
          v-model="showNotActiveAreas"
          class="float-left mt-3 ml-2"
        >
          サービス外のエリアを表示する
        </b-form-checkbox>
      </b-col>
      <b-col><b-button class="float-right mt-3 mr-2" :to="{name: 'EditAreas', params: { id: 'new'}}">+ エリアを作成</b-button></b-col>
    </b-row>
    <b-row>
      <b-col v-for="item in items" :key="item._id" cols=12 md=3 class="mt-3" v-show="show(item)">
        <b-card>
          <b-card-body>
            <b-card-title>{{item.name}} ({{item.total}})</b-card-title>
            <b-card-sub-title>
              <p v-if="item.status">サービス提供エリア</p>
              <p v-else>サービス外エリア</p>
            </b-card-sub-title>
            <b-card-text>
              <li v-for="code in item.postalCodes" :key="code.postalCode">
                <p v-if="code.isActive">
                  郵便番号: {{ code.postalCode }} ({{code.count}})
                </p>
              </li>
              <div>作成: {{ item.created_at }}</div>
              <div>最終更新: {{ item.updated_at }}</div>
            </b-card-text>
          </b-card-body>
          <b-button :to="{ name: 'EditAreas', params: { id: item._id}}" variant="primary">編集</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Axios from 'axios'
  export default {
    data() {
      return {
        items: [],
        showNotActiveAreas: false
      }
    },
    async mounted(){
      this.items = await this.getAreas()
    },
    methods: {
      async getAreas(){
        const header = {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
        try {
          const res = await Axios.get('/v1/areas/index', header)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      /**
       * @param {Object} area - エリアオブジェクト
       * @return - エリアを表示するか否か
       */
      show(area){
        if (this.showNotActiveAreas){
          return true
        }
        return area.status
      }
    }
  }
</script>