<template>
  <div>
    <b-buddon></b-buddon>
    <b-table striped hover responsive :items="items" :fields="fields">
      <template #cell(areaCode)="data">
        <b-button size="sm" class="mr-2" v-on:click="fetchAreaCodes(data.item._id)" >候補取得</b-button>
        <p v-if="processing === data.item._id"><v-fa :icon="['fas', 'spinner']" pulse size="2x" /></p>
        <div v-for="item in data.item.candidates" v-bind:key="item.code">
          <input type="radio" id="item.code" v-bind:value="item.code" v-model="data.item.rakuten_area_code">
          <label for="item.code">{{ item.name }} ({{ item.code }})</label>
        </div>
        <p>現在値: {{ data.item.rakuten_area_code }}</p>
        <b-button style="background-color: #5F769A" size="sm" class="mr-2" v-on:click="save(data.item._id)" >保存</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
  import Axios from "axios"
  export default {
    data() {
      return {
        fields: [
          '_id',
          'channel_id',
          'name',
          'postalcode',
          'areaCode',
          'prefectural',
          'address',
          'building',
          'last_name',
          'first_name',
          'last_name_kana',
          'first_name_kana',
          'tel',
        ],
        items: [],
        processing: '',
        header: {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
      }
    },
    async mounted() {
      this.items = await this.getAddresses()
    },
    methods: {
      async getAddresses(){
        try {
          const res = await Axios.get('/v1/addresses/index', this.header)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      async fetchAreaCodes(id) {
        try {
          this.processing = id
          const address = this.items.find(item => item._id === id)
          address.candidates = await this.getRakutenAreaCodes(address.postalcode)
          this.items = this.items.map(item => item._id === id ? address : item)
          this.processing = ''
        } catch (e) {
          alert(e)
        }
      },
      async getRakutenAreaCodes (postalCode) {
        try {
          const payload = {params: {'postalCode': postalCode}, 'headers': this.header.headers}
          const res = await Axios.get('/v1/addresses/rakuten-area-codes', payload)
          return res.data
        } catch (e) {
          alert(e)
        }
      },
      async save(id){
        this.processing = id
        const item = this.items.find(item => item._id === id)
        try {
          const res = await Axios.post('/v1/addresses/edit', item, this.header)
          this.processing = ''
          return res.data
        } catch (e) {
          console.error(e)
        }
      }
    }
  }
</script>