<template>
  <div id="app">
    <div v-if="loggedIn">
      <b-row>
        <b-col md=2 class="sidebar d-none d-md-block sidebar-wrapper"><Sidebar/></b-col>
        <b-col md=10 class="mainpage">
          <Topbar/>
          <router-view/>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <router-view/>
    </div>
  </div>
</template>
<script>
import Sidebar from './views/Sidebar.vue'
import Topbar from './views/Topbar.vue'
export default {
  computed: {
    loggedIn(){
      return this.$store.state.loggedIn
    }
  },
  components: {
    Sidebar,
    Topbar
  },
}
</script>
<style>
html {
  background: #324158;
}
.sidebar {
  background-color: #324158;
}
.sidebar-wrapper {
  padding: 0;
}
.mainpage {
  background-color: #EEF1F6;
  min-height: 100vh;
}
</style>
