<template>
    <div>
        <h1>配送日: {{ date }}</h1>
        <h2>〒{{ areaName }}</h2>
        <h2>SlackChannel: {{ slackchannel }}</h2>
        <div>
            <label for="menuId">ID</label>
            <b-form-input 
            id="menuId" v-model="menuId"
            v-bind:readonly="true"
            />
        </div>
        <div class="mt-2">
            <label for="description">スクリプト</label>
            <b-form-textarea id="description" v-model="description"></b-form-textarea>
        </div>
        <div>
            <b-row>
                <b-col cols=12>
                <div class="mt-2 float-right">
                    <label for="deadline">締め切り時間</label>
                    <vue-timepicker 
                    v-model="deadline"
                    value="deadline.objectTime"
                    :format="deadline.format"
                    :minute-interval="minuteInterval"
                    :default-value="date"
                    :hour-range="hourRange"
                    placeholder="時間"
                    hour-label="時"
                    minute-label="分"
                    id="deadline"
                    />
                </div>
                </b-col>
            </b-row>
        </div>
            <b-row class="mt-4" align-v="end">
                <b-col cols="12" class="mt-3">
                    <b-button v-b-modal.bentolist block>+ お弁当を追加</b-button>
                    <b-modal id="bentolist" title="お弁当一覧">
                    <p class="my-4">取扱中のお弁当一覧が表示されます</p>
                    <b-list-group v-for="lunchBox of catalog" v-bind:key="lunchBox._id">
                        <b-list-group-item button v-on:click="pushLunchBox(lunchBox)">
                        {{ lunchBox.name }}
                        {{ lunchBox.price }} 円
                        </b-list-group-item>
                    </b-list-group>
                    </b-modal>
                </b-col>
                <b-col v-for="box of lunchBoxes" v-bind:key="box._id" cols="12" md="6" class="mt-3">
                    <MenuItem v-model="box.min" :lunchBox="box" :menuId="menuId" :deliveryDate="date" :isMinimumOrderChangable="isMinimumOrderChangable"/>
                    <b-button v-on:click="remove(lunchBox)" class="mt-3 ml-3" variant="danger">削除</b-button>
                </b-col>
            </b-row>
            <b-button v-on:click="save" block class="mt-3" v-bind:disabled="isPushable">保存</b-button>
            <b-button v-on:click="post" block class="mt-3" v-bind:disabled="isPushable">今すぐ配信</b-button>
            <b-button v-on:click="close" block class="mt-3" v-bind:disabled="isPushable">このメニューの注文を集計</b-button>
    </div>
</template>
<script>
import MenuItem from './MenuItem.vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import Axios from 'axios';
import moment from 'moment';

export default {
    components: {
        MenuItem,
        VueTimepicker
    },
    async mounted(){
      if (this.$route.params.id !== 'new'){
        this.set(await this.get(this.$route.params.id))
      } else {
        this.reset()
      }
    },
    computed: {
        isPushable(){
            return this.menuId === ''
        },
        isMinimumOrderChangable(){
            return moment(this.date).format("YYYYMMDD") > moment(new Date()).format("YYYYMMDD") || process.env.NODE_ENV === 'development'
        }
    },
    data(){
        return {
            date: null,
            menuId: '',
            catalog: [],
            lunchBoxes: [],
            description: '',
            deadline: {
                HH: '11',
                mm: '00',
                format: 'HH:mm', 
            },
            // hourRange: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 23],
            // minuteInterval: 15,
            areaName: '',
            slackchannel: '',
            header: {Authorization: `JWT ${this.$store.state.authentication.token}`}
        }
    },
    methods: {
        /**
         * メニューを保存する
         * @return {Void}
         */
        async save(){
            try {
                const payload = {
                    'description': this.description,
                    'lunchBoxes': this.lunchBoxes,
                    'deliveryDate': moment(this.date).format("YYYYMMDD"),
                    'areaName': this.areaName,
                    'deadline':moment(this.date).hour(this.deadline.HH).minute(this.deadline.mm),
                    '_id': this.$route.params.id
                }
                await Axios.post("/v1/menus/edit", payload, {headers: this.header})
                alert(`メニューを登録しました。 締切時刻: ${payload.deadline}`)
                this.set(await this.get(this.$route.params.id))
            } catch (e) {
                alert(`Error: ${e}`)
            }
        },
        /**
         * メニューに弁当を追加する
         * @param {Object} lunchBox - 弁当オブジェクト
         * @return {Void}
         */
        pushLunchBox(lunchBox){
            if (this.lunchBoxes.includes(lunchBox)){
                alert("既に追加されています。")
            } else {
                this.lunchBoxes.push(lunchBox)
            }
        },
        /**
         * エンドポイントから各種アイテムを取得する
         * @param {String} id - 取得するアイテム名
         * @return {Array} - 取得したアイテム一覧
         */
        async get(id){
            try {
                const payload = {params: {'_id': id}, 'headers': this.header}
                const res = await Axios.get('/v1/menus/view', payload)
                return res.data
            } catch (e) {
                alert(`Error: ${e}`)
            }
        },
        /**
         * @param {Object} menu - メニューオブジェクト
         * @return {void}
         */
        set(menu){
            if (menu) {
                this.lunchBoxes = menu.lunchBoxes
                this.description = menu.description
                this.menuId = menu._id
                this.deadline= {
                    HH: moment(menu.deadline).format('HH'),
                    mm: moment(menu.deadline).format('mm'),
                    format: 'HH:mm', 
                }
                this.date = new Date(menu.deliveryDate)
                this.areaName = menu.areaName
                this.slackchannel = menu.channel
            } else {
                this.resetMenu()
            }
        },
        resetMenu() {
            this.selectedArea = {}      
            this.lunchBoxes = []
            this.description = ""
            this.menuId = ""
        },
        async post(){
            try {
                const res = await Axios.post(
                    `/v1/menus/post`,
                    { _id: this.menuId },
                    {headers: this.header}
                )
                return res.data
            } catch (e) {
                alert(`Error: ${e}`)
            }
        },
        async close () {
            try {
                const res = await Axios.post(
                    `/v1/menus/close`,
                    { _id: this.menuId },
                    {headers: this.header}
                )
                return res.data
            } catch (e) {
                alert(`Error: ${e}`)
            }
        },
        remove(lunchBox){
            const idx = this.lunchBoxes.indexOf(lunchBox)
            this.lunchBoxes.splice(idx, 1)
        }
    }
}
</script>
