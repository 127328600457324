<template>
  <div>
    <b-row class="mt-4">
      <b-col cols="12" md="3" class="mt-2">
        <v-date-picker v-model="date"/>
      </b-col>
      <b-col cols="12" md="9" class="mt-2">
        <b-button block style="background-color: #5F769A" v-on:click="postMenusToday">今すぐ全体に配信</b-button>
        <b-card class="float-left w-100">
          <div>
            <b-row>
              <b-col cols="12" md="8" class="mt-2">{{ ymd.format("YYYY年M月D日(ddd)") }}</b-col>
            </b-row>
          </div>
          <b-row>
            <b-col cols=12 md=4 v-for="menu in menus" :key="menu._id">
              <b-card style="height:100%">
                <b-card-title>〒{{ menu.areaName }}</b-card-title>
                <b-card-sub-title>SlackChannel:{{ menu.channel }}</b-card-sub-title>
                <b-list-group>
                  <b-list-group-item v-for="box in menu.lunchBoxes" :key="box._id">{{box.name}}  {{box.price}}円 ({{box.store_name}})</b-list-group-item>
                </b-list-group>
                <b-card-text>
                  スクリプト: {{menu.description}}<br>
                  締切: {{menu.deadline}}
                </b-card-text>
                <b-button :to="{name: 'EditMenu', params: { id: menu._id}}">編集</b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Axios from 'axios';
import moment from 'moment';

moment.locale('ja');
export default {
  data() {
    return {
      date: null,
      id: null,
      menuId: '',
      script: '',
      deadline: {
        HH: '11',
        mm: '00',
        format: 'HH:mm', 
      },
      menus: [],
      header: {Authorization: `JWT ${this.$store.state.authentication.token}`}
    }
  },
  async mounted(){
    this.catalog = await this.getItems("lunch-boxes")
    this.date = new Date()
  },
  computed: {
    ymd(){
      return moment(this.date)
    },
  },
  watch: {
    date: async function(val) {
      this.menus = await this.getMenus(moment(val).format("YYYYMMDD"))
    }
  },
  methods: {
    /**
     * エンドポイントから各種アイテムを取得する
     * @param {String} name - 取得するアイテム名
     * @return {Array} - 取得したアイテム一覧
     */
    async getItems(name){
      try {
        const res = await Axios.get(
          `/v1/${name}/index`,
          {headers: this.header}
        )
        return res.data
      } catch (e) {
        alert(`Error: ${e}`)
      }
    },
    /**
     * @param {String} date - 取得したい日付
     * @return {Object} - 該当するメニューオブジェクト
     */
    async getMenus(date){
      try {
        const filter = {"date" : date}
        const res = await Axios.get("/v1/menus/index", {params:filter, headers: this.header})
        return res.data
      }catch(e){
        alert(`Error: ${e}`)
      }
    },
    /**
     * 本日のメニュー（ランダム生成）を配信する
     * @return {Object} - 該当するメニューオブジェクト
     */
    async postMenusToday(){
      try {
        const res = await Axios.get("/v1/menus/post-today", {headers: this.header})
        alert('本日のメニューを配信しました')
        return res.data
      }catch(e){
        alert(`Error: ${e}`)
      }
    },
  }
}
</script>