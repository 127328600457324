<template>
  <div>
    <div class="mt-3">
      <b-row>
        <b-col>
          <label for="word">単語<small class="text-danger">必須</small></label>
          <b-form-input id="word" v-model="form.text"></b-form-input>
        </b-col>
        <b-col>
          <label for="word">種別<small class="text-danger">必須</small></label>
          <b-form-select v-model="form.ng_word" :options="options" id="is-active"></b-form-select>
        </b-col>
        <b-col align-self="end">
          <b-button style="background-color: #5F769A" block v-on:click="saveWord">+検索ワードを追加</b-button>
        </b-col>
      </b-row>
    </div>
    <b-row v-for="item in items" v-bind:key="item._id" class="mt-3">
      <b-col>{{ item.text }}</b-col>
      <b-col><b-badge v-show="item.ng_word" variant="warning">NG</b-badge></b-col>
      <b-col>
        <b-button block v-on:click="remove(item._id)" variant="danger">削除</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import Axios from 'axios'
  import { v4 as uuidv4 } from 'uuid'
  export default {
    data() {
      return {
        items: [],
        form: {ng_word: false, text: ''},
        options: [{text: 'NGワード', value: true}, {text: '検索ワード', value: false}]
      }
    },
    async mounted(){
      this.items = await this.getWords()
    },
    methods: {
      async getWords() {
        const header = {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
        try {
          const res = await Axios.get('/v1/search-words/index', header)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      async saveWord() {
        if (!this.form.text) {
          alert("単語を入力してください")
        } else {
          const header = {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
          if (!this.form._id) {
            this.form._id = uuidv4()
          }
          try {
            const res = await Axios.post('/v1/search-words/edit', this.form, header)
            this.items.push({...this.form})
            this.form.text = ''
            this.form._id = ''
            return res.data
          }catch(e){
            alert(`Error: ${e}`)
          }        
        }
      },
      async remove(id) {
        const header = {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
        try {
          const res = await Axios.post('/v1/search-words/delete', {_id: id}, header)
          this.items = this.items.filter(item => item._id != id)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }        
      }
    }
  }
</script>