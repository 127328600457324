<template>
  <div class="login-form text-light">
    <div class="text-center">
      <h1>🍱</h1>
      <h2>Slack Bento</h2>
    </div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        label="メール"
        label-for="input-1"
        description="We'll never share your email with anyone else."
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          required
          placeholder="Enter email"
        ></b-form-input>
      </b-form-group>
      <div>
        <b-form @submit.stop.prevent>
          <label for="text-password">パスワード</label>
          <b-input type="password" id="text-password" required aria-describedby="password-help-block" v-model="form.password"></b-input>
          <b-form-text id="password-help-block">
            Your password must be 8-20 characters long, contain letters and numbers, and must not
            contain spaces, special characters, or emoji.
          </b-form-text>
        </b-form>
      </div>
      <div><b-button block type="submit" class="login-button">ログイン</b-button></div>
    </b-form>
  </div>
</template>
<script>
import Axios from "axios"
export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      show: true
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.login();
      // alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.password = ''
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    async login() {
      try {
        if(!this.form.password) {alert('パスワードを入力してください')}
        else {
          const res = await Axios.post("/v1/login", this.form);
          this.$store.dispatch("login", res.data);
          this.$router.push('deliveries')
        }
      } catch(error) {
        alert(`処理が正しく行えませんでした。時間をおいてやり直してください。${error}`)
      }
    }
  }
}
</script>
<style>
.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
  /* text-align: center;一応BOX内の文字も中央寄せ */;
}
.login-button {
  background-color: #5F769A;
}
</style>