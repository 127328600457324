<template>
  <div>
    <b-table striped hover responsive :items="items" :fields="fields">
      <template #cell(refund)="data">
        <b-button size="sm" class="mr-2" v-on:click="refund(data.item._id)" :disabled="refunded(data.item.status, data.item._id)">返金</b-button>
        <p v-if="processing === data.item._id"><v-fa :icon="['fas', 'spinner']" pulse size="2x" /></p>
      </template>
    </b-table>
  </div>
</template>

<script>
  import Axios from "axios"
  export default {
    data() {
      return {
        fields: [
          '_id',
          'created_at',
          'status',
          'calculated',
          'sent',
          'shipped',
          'amount',
          'customer',
          'refund',
          'preorder_date',
          'paid_date',
          'refunded_date',
          'receipt_url',
          'refunded_id',
          'channel_id',
          'user_id',
          'menu_id',
          'lunchBox_id',
        ],
        items: [],
        processing: '',
        header: {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
      }
    },
    async mounted() {
      this.items = await this.getOrders()
    },
    methods: {
      refunded(status, id){
        return status != 'paid' || this.processing === id
      },
      async getOrders(){
        try {
          const res = await Axios.get('/v1/orders/index', this.header)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      async refund(id) {
        try {
          this.processing = id
          await Axios.post('/v1/orders/refund', {_id: id}, this.header)
          this.items = await this.getOrders()
          this.processing = ''
        }catch(e){
          alert(`返金通信処理中にエラーが発生しました: ${e}`)
        }        
      }
    }
  }
</script>