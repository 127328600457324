<template>
  <div>
    <b-form-checkbox-group
      v-model="checked"
      :options="options"
      class="mt-2 ml-1"
    ></b-form-checkbox-group>
    <b-row>
      <b-col cols=12 md=4 v-for="item in items" v-bind:key="item._id" class="mt-3" v-show="checked.includes(item.status)">
        <b-card>
          <b-card-title>{{item.company}}への配達</b-card-title>
          <b-card>
            <h4>注文情報</h4>
            配達日: {{ dateFormat(item.deliveryDate) }}<br><br>
            <li v-for="order in item.orders" v-bind:key="order.LUNCHBOX + item._id">{{ lunchName(order.LUNCHBOX) }} {{ order.count }}個</li><br>
            <b-card>
              <h5>配達先</h5>
              {{ extractAddress(item.address) }}<br>
              {{ extractTel(item.address) }}<br>
              {{ extractName(item.address) }}<br><br>
            </b-card>
            作成: {{ dateFormat(item.created_at) }}<br>
            更新: {{ dateFormat(item.updated_at) }}
          </b-card>
          <b-card v-if="item.status === 'ordered_to_rakuten'" class="mt-2">
            <h4>ジョブ情報</h4>
            <div v-for="job in item.jobs" v-bind:key="job.jobid">
              <b-card>
                <div>ジョブID: <a v-bind:href="`/logs/recoben/rakuten-delivery/${job.jobid}.log`">{{ job.jobid }}</a></div>
                <div>弁当URL: <a v-bind:href="`https://delivery.rakuten.co.jp${job.url}`">{{ job.url }}</a></div>
                <div><b-badge v-if="job.failed" variant="danger">楽天で失敗</b-badge></div>
                <div class="mt-2"><b-button v-b-modal="job.jobid" :disabled="job.failed">返金処理</b-button></div>
                <b-modal :id="job.jobid" title="返金処理" @ok="refundJob(job.jobid)">
                  <p class="my-4">以下のジョブに紐づく注文を返金しますか？</p>
                  <p>Scrapy jobid:  <b>{{ job.jobid }}</b></p>
                  <p v-if="processing"><v-fa :icon="['fas', 'spinner']" pulse size="2x" /></p>
                </b-modal>
              </b-card>
            </div>
          </b-card>
          <label for="status">配送状態</label>
          <b-form-select v-model="item.selectedStatus" :options="options" id="status"></b-form-select><b-button style="background-color: #5F769A" v-on:click="save(item)" class="mt-3">保存</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Axios from 'axios'
import moment from 'moment'
moment.locale('ja');
export default {
  data() {
    return {
      items: [],
      options: [
        {'value': 'stay', 'text': '未配達'},
        {'value': 'delivering', 'text': '配送中'},
        {'value': 'delivered', 'text': '配達完了'},
        {'value': 'ordered_to_rakuten', 'text': '楽天デリバリーに発注'},
      ],
      lunchBoxes: [],
      header: {Authorization: `JWT ${this.$store.state.authentication.token}`},
      checked: ['ordered_to_rakuten'],
      processing: false
    }
  },
  async mounted(){
    this.items = await this.getDeliveries()
    this.lunchBoxes = await this.getLunchBoxes()
  },
  methods: {
    extractAddress(address){
      return address.split(",")[0]
    },
    extractTel(address){
      return address.split(",")[1]
    },
    extractName(address){
      return address.split(",")[2]
    },
    async getItems(endpoint){
      try {
        const res = await Axios.get(endpoint, {headers: this.header})
        return res.data
      }catch(e){
        alert(`Error: ${e}`)
      }
    },
    async getDeliveries(){
      let deliveries = await this.getItems('/v1/deliveries/index')
      return deliveries.map(delivery => {
        delivery.selectedStatus = delivery.status
        delivery.jobs = delivery.jobs.map(job => {
          job.jobid = job.jobid ?? job.job.jobid
          return job
        })
        return delivery
      })
    },
    async getLunchBoxes(){
      return await this.getItems('/v1/lunch-boxes/index')
    },
    /**
     * 配送オブジェクトを更新する
     * @param {Object} delivery - 配送オブジェクト
     * @return {Object} - 修正した配送オブジェクト
     */
    async save(delivery){
      if (delivery.selectedStatus){
        delivery.status = delivery.selectedStatus
        delivery.selectedStatus = undefined
        try {
          const res = await Axios.post('/v1/deliveries/edit', delivery, {headers: this.header})
          return res.data
        } catch (e) {
          alert(`Error: ${e}`)
        }
      } else {
        alert('配送ステータスが選択されていません')
      }
    },
    async refundJob(jobid){
      try {
        this.processing = true
        await this.refund(jobid)
        this.items = await this.getDeliveries()
        this.processing = false
        alert(`返金処理が完了しました\n Scrapy Jobid:\n${jobid}`)
      } catch (e) {
        alert(`返金処理中にエラーが発生しました\n Scrapy Jobid:\n${jobid}\n${e}`)
      }
    },
    async refund(jobid){
      try {
        const res = await Axios.post('/v1/deliveries/refund', {jobid: jobid}, {headers: this.header})
        return res.data
      } catch (e) {
        alert(`返金通信処理中にエラーが発生しました: ${e}`)
        throw e
      }
    },
    lunchName(lunchBoxId){
      const lunchBox = this.lunchBoxes.find(item => item._id === lunchBoxId)
      if ( lunchBox !== undefined){
        return lunchBox.name
      } else {
        return ''
      }
    },
    dateFormat(date){
      return moment(date).format('YYYY-MM-DD a h:mm:ss')
    }
  },
  components: {
  }
}
</script>