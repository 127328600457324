<template>
  <div>
    <b-row>
      <b-col cols=12 md=9 class="mt-3">
        <b-card>
          <div>
            <validation-provider rules="required" v-slot="{ errors }">
              <label for="store-name">店舗名<small class="text-danger">必須</small></label>
              <b-form-input id="store-name" v-model="name"></b-form-input>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div>
            <label for="status">店舗タイプ</label>
            <b-form-select v-model="store_type" :options="store_types" id="store_types"></b-form-select>
          </div>
          <div>
            <validation-provider rules="required" v-slot="{ errors }">
              <label for="rakuten_store_id">楽天デリバリー店舗ID<small class="text-danger">必須</small></label>
              <b-form-input id="rakuten_store_id" v-model="rakuten_store_id"></b-form-input>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="mt-3">
            <div>営業時間</div>
            <div v-for="hour in business_hours" :key="hour._id">{{ hour.start }}-{{ hour.end }}</div>
          </div>
          <div class="mt-3">
            <div>定休日</div>
            <div v-for="day in closing_days" :key="day._id">{{ day | moment }}</div>
          </div>
        </b-card>
        <div class="mt-3">
          <b-row>
            <b-col cols=6 md=10>
              郵便番号<small class="text-danger">必須</small>
            </b-col>
            <b-col cols=6 md=2>
              <b-button v-b-modal.postalcode block>+ 追加</b-button>
              <b-modal id="postalcode" title="郵便番号の登録" @ok="pushPostalCode">
                <div>
                  <validation-provider rules="required|numeric|digits:7" v-slot="{ errors }">
                    <label for="postal-code">郵便番号<small class="text-danger">半角数字7桁</small></label>
                    <b-form-input id="postal-code" v-model="postalCode"></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div>
                  <validation-provider rules="required|numeric" v-slot="{ errors }">
                    <label for="wait-minutes">待ち時間(分)<small class="text-danger">半角数字</small></label>
                    <b-form-input id="wait-minutes" v-model="waitMinutes"></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div>
                  <validation-provider rules="required|numeric" v-slot="{ errors }">
                    <label for="at-least-price">最低注文価格(円)<small class="text-danger">半角数字</small></label>
                    <b-form-input id="at-least-price" v-model="atLeastPrice"></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div>
                  <validation-provider rules="required|numeric" v-slot="{ errors }">
                    <label for="shipping-price">送料(円)<small class="text-danger">半角数字</small></label>
                    <b-form-input id="shipping-price" v-model="shippingPrice"></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <label for="status">ステータス<small class="text-danger">選択必須</small></label>
                    <b-form-select v-model="postalCodeIsActive" :options="options" id="status"></b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </b-modal>
            </b-col>
          </b-row>
          <b-card>
            <b-row v-for="code in postal_codes" v-bind:key="code.code" class="mt-3">
              <b-col>〒{{ code.postal_code }}</b-col>
              <b-col>待ち時間:{{ code.wait_minutes }}分</b-col>
              <b-col>最低注文金額:{{ code.at_least_price }}円</b-col>
              <b-col>追加送料:{{ code.shipping_price }}円</b-col>
              <b-col>アクティブ:{{ code.is_active }}</b-col>
              <b-col><b-button variant="danger" v-on:click="remove(code)">削除</b-button></b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
      <b-col cols=12 md=3 class="mt-3">
        <b-card>
          <div>
            <label for="status">ステータス</label>
            <b-form-select v-model="selected" :options="options" id="is-active"></b-form-select>
          </div>
          <div class="mt-3">
            <b-row>
                <b-col cols=6><b-button style="color: #949DAE;background-color: white" block>削除</b-button></b-col>
                <b-col cols=6><b-button style="background-color: #5F769A" block v-on:click="save()">保存</b-button></b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Axios from 'axios';
import moment from 'moment';
import { ValidationProvider, extend } from 'vee-validate';
import { required, numeric, digits } from 'vee-validate/dist/rules';
  extend('required', {
    ...required,
    message: '必須項目です'
  });
  extend('numeric', {
    ...numeric,
    message: '半角数字で入力してください'
  });
  extend('digits', {
    ...digits,
    message: '桁数が異なります'
  });
  export default {
    components: {
      ValidationProvider
    },
    filters: {
        moment: function (date) {
            return moment(date).format("YYYY年M月D日(ddd)");
        }
    },
    data() {
      return {
        id: '',
        name: '',
        store_type: '',
        rakuten_store_id: '',
        selected: null,
        postalCode: '',
        waitMinutes: 0,
        atLeastPrice: 1500,
        shippingPrice: 0,
        postalCodeIsActive: null,
        postal_codes: [],
        postal_code: '',
        store_types: [
          {value: 'rakuten', text: '楽天'},
          {value: 'own', text: '自社'},
        ],
        business_hours: [],
        closing_days: [],
        options: [
            {value: null, text: '状態を選択してください'},
            {value: true, text: 'サービス提供中'},
            {value: false, text: 'サービス未提供'}
        ],
        error: '',
        header: {Authorization: `JWT ${this.$store.state.authentication.token}`}
      }
    },
    async mounted(){
      if (this.$route.params.id !== 'new'){
        this.set(await this.get(this.$route.params.id))
      } else {
        this.reset()
      }
    },
    methods: {
      /**
       * エリアに郵便番号を追加する
       * @return {void}
       */
      pushPostalCode(){
        const postalCode = {
          "postal_code": this.postalCode,
          "wait_minutes": this.waitMinutes,
          "shipping_price": this.shippingPrice,
          "is_active": this.postalCodeIsActive
        }
        if (this.postal_codes.includes(postalCode)){
          alert("既に追加されています。")
        } else if (this.postalCode.match(/[^0-9]+/) || this.postalCode.length !== 7) {
          alert("半角数字7桁で入れてください")
        } else if(this.postalCode === null) {
          alert("郵便番号のステータスが選択されていません")
        } else {
          this.postal_codes.push(postalCode)
        }
      },
      /**
       * 郵便番号を店舗から削除する
       * @param {Object} code - 郵便番号オブジェクト
       * @return {Void}
       */
      remove(code){
        this.postal_codes = this.postal_codes.filter(masterCode => masterCode != code)
      },
      async get(id){
        try{
          const payload = {params: {'_id': id}, 'headers': this.header}
          const res = await Axios.get('/v1/stores/view', payload)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      set(store){
        this.id = store._id
        this.name = store.name
        this.store_type = store.store_type
        this.rakuten_store_id = store.rakuten_store_id
        this.selected = store.is_active
        this.postal_codes = store.postal_codes
        this.business_hours = store.business_hours
        this.closing_days = store.closing_days
      },
      reset(){
        this.id = ''
        this.name = ''
        this.selected = null
        this.postal_codes = []
        this.business_hours = []
        this.closing_days = []
      },
      /**
       * @param postalNumbers {Array} - 郵便番号の配列
       * @return {void}
       */
      async save(){
        const header = {headers: this.header}
        const payload = {
          '_id': this.id,
          'name': this.name,
          'store_type': this.store_type,
          'rakuten_store_id': this.rakuten_store_id,
          'is_active': this.selected,
          'postal_codes': this.postal_codes
        }
        try {
          if(!this.name || !this.postal_codes) {
            alert('必須項目を入力してください')
          } else {
            await Axios.post('/v1/stores/edit', payload, header)
            alert(`${this.name}を登録しました`)
          }
          
        }catch(e){
          alert(`Error: ${e}`)
        }
      }
    }
  }
</script>