<template>
    <div>
        <b-card class="mt-3">
            <div>新規メッセージの送信</div>
            <div>
                <label for="channel-id">チャンネルID(必須)</label>
                <b-form-input id="channel-id" v-model="channelId" :readonly="toAll"></b-form-input>
                <input type="checkbox" id="checkbox" v-model="toAll">
                <label for="checkbox">全てのチャンネルに送信する</label>
            </div>
            <br>
            <div>
                <label for="message">メッセージ(必須)</label>
                <b-form-input id="message" v-model="message"></b-form-input>
            </div>
            <br>
            <div>
                <label for="blocks">blocks(任意)</label>
                <b-form-textarea id="blocls" v-model="blocks"></b-form-textarea>
                <a href='https://app.slack.com/block-kit-builder/T3V9UHKB8#%7B%22blocks%22:%5B%7B%22type%22:%22section%22,%22text%22:%7B%22type%22:%22plain_text%22,%22text%22:%22This%20is%20a%20plain%20text%20section%20block.%22,%22emoji%22:true%7D%7D%5D%7D' target="_blank">? Slack Block Kit Builder</a>
            </div>
            <b-row class="mt-3">
                <b-col cols=6><b-button style="color: #949DAE;background-color: white" block>クリア</b-button></b-col>
                <b-col cols=6><b-button style="background-color: #5F769A" block  v-on:click="postMessage(channelId,message,blocks)">送信</b-button></b-col>
            </b-row>
        </b-card>
        <div clsss="mt-3">
            <div>送信履歴</div>
        </div>
    </div>
</template>

<script>
  import Axios from 'axios'
  export default {
    data(){
      return{
        toAll: false
      }
    },
    methods: {
      async postMessage(id,message, blocks){
        try {
            const header = {headers: {Authorization: `JWT ${this.$store.state.authentication.token}`}}
            const payload = { id: id, message: message, blocks: blocks }
            const target = this.toAll ? 'post-to-all' : 'post'
            if (!message){
              alert ('メッセージは必須です')
            } else if (this.toAll !== true && !id){
              alert ('チャンネルIDを入力するか、全てのチャンネルに送信を選択してください')
            } else {
              const res = await Axios.post(`/v1/messages/${target}`, payload, header)
              if(res.status == 201) alert(`送信しました(201)`)
              return res.data
            }
        }catch(e){
          alert(`Error: ${e}`)
        }
      }
    }
  }
</script>