<template>
  <div>
    <b-row>
      <b-col cols=12 md=9 class="mt-3">
        <b-card>
          <div>
            <validation-provider rules="required" v-slot="{ errors }">
              <label for="area-name">エリア名<small class="text-danger">必須</small></label>
              <b-form-input id="area-name" v-model="name"></b-form-input>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </b-card>
        <div class="mt-3">
          <b-row>
            <b-col cols=6 md=10>
              郵便番号<small class="text-danger">必須</small>
            </b-col>
            <b-col cols=6 md=2>
              <b-button v-b-modal.postalcode block>+ 追加</b-button>
              <b-modal id="postalcode" title="郵便番号の登録" @ok="pushPostalCode">
                <div>
                  <validation-provider rules="required|numeric|digits:7" v-slot="{ errors }">
                    <label for="postal-code">郵便番号<small class="text-danger">半角数字7桁</small></label>
                    <b-form-input id="postal-code" v-model="postalCode" v-validate="required"></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <label for="status">ステータス<small class="text-danger">選択必須</small></label>
                    <b-form-select v-model="postalCodeIsActive" :options="options" id="status"></b-form-select>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
              </b-modal>
            </b-col>
          </b-row>
          <b-card>
            <b-table striped hover :items="postalCodes"></b-table>
          </b-card>
        </div>
      </b-col>
      <b-col cols=12 md=3 class="mt-3">
        <b-card>
          <div>
            <label for="status">ステータス</label>
            <b-form-select v-model="selected" :options="options" id="status"></b-form-select>
          </div>
          <div class="mt-3">
            <b-row>
                <b-col cols=6><b-button style="color: #949DAE;background-color: white" block>削除</b-button></b-col>
                <b-col cols=6><b-button style="background-color: #5F769A" block v-on:click="save()">保存</b-button></b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Axios from 'axios';
import { ValidationProvider, extend } from 'vee-validate';
import { required, numeric, digits } from 'vee-validate/dist/rules';
  extend('required', {
    ...required,
    message: '必須項目です'
  });
  extend('numeric', {
    ...numeric,
    message: '半角数字で入力してください'
  });
  extend('digits', {
    ...digits,
    message: '桁数が異なります'
  });
  export default {
    components: {
      ValidationProvider
    },
    data() {
      return {
        id: '',
        name: '',
        selected: null,
        postalCodeIsActive: null,
        postalCodes: [],
        postalCode: '',
        options: [
            {value: null, text: '状態を選択してください'},
            {value: true, text: 'サービス提供中'},
            {value: false, text: 'サービス未提供'}
        ],
        error: '',
        header: {Authorization: `JWT ${this.$store.state.authentication.token}`}
      }
    },
    async mounted(){
      if (this.$route.params.id !== 'new'){
        this.set(await this.get(this.$route.params.id))
      } else {
        this.reset()
      }
    },
    methods: {
      /**
       * エリアに郵便番号を追加する
       * @return {void}
       */
      pushPostalCode(){
        const postalCode = {"postalCode": this.postalCode, "isActive": this.postalCodeIsActive}
        if (this.postalCodes.includes(postalCode)){
          alert("既に追加されています。")
        } else if (this.postalCode.match(/[^0-9]+/) || this.postalCode.length !== 7) {
          alert("半角数字7桁で入れてください")
        } else if(this.postalCode === null) {
          alert("郵便番号のステータスが選択されていません")
        } else {
          this.postalCodes.push(postalCode)
        }
      },
      async get(id){
        try{
          const payload = {params: {'_id': id}, 'headers': this.header}
          const res = await Axios.get('/v1/areas/view', payload)
          return res.data
        }catch(e){
          alert(`Error: ${e}`)
        }
      },
      set(area){
        this.id = area._id
        this.name = area.name
        this.selected = area.status
        this.postalCodes = area.postalCodes
      },
      reset(){
        this.id = ''
        this.name = '',
        this.selected = null,
        this.postalCodes = []
      },
      /**
       * @param postalNumbers {Array} - 郵便番号の配列
       * @return {void}
       */
      async save(){
        const header = {headers: this.header}
        const payload = {'_id': this.id, 'name': this.name, 'status': this.selected, 'postalCodes': this.postalCodes}
        try {
          if(!this.name || !this.postalCodes) {
            alert('必須項目を入力してください')
          } else {
            await Axios.post('/v1/areas/edit', payload, header)
            alert(`${this.name}を登録しました`)
          }
          
        }catch(e){
          alert(`Error: ${e}`)
        }
      }
    }
  }
</script>